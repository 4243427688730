import * as React from "react";

import SecondaryButton from "../../Button/SecondaryButton";

import {
  Container,
  StyledDescription,
  StyledHeading,
  StyledLinks,
  StyledSection,
  StyledServiceItems
} from "./style";


const ServiceHero = ({
  pageTitle,
  pageDescription,
  serviceItems,
  Component
}) => {
  const pageTitleArray = pageTitle.split(' ');
  const [, , ...rest] = pageTitleArray;

  const serviceItemsList = serviceItems.map((serviceItem, i, arr) => {
    return (
      <p>
        <span>/</span> { serviceItem }&nbsp;
      </p>
    );
  });

  return (
    <StyledSection>
      <Container>
        <StyledHeading>
          <span>{ pageTitleArray[0] + ` ` + pageTitleArray[1] + ` ` }</span>
          <span>{ rest.join(' ') }</span>
        </StyledHeading>
        <StyledDescription>
          { pageDescription }
        </StyledDescription>
        <StyledServiceItems>
          { serviceItemsList }
        </StyledServiceItems>
        <StyledLinks>
          <SecondaryButton
            linkLocation="/contact"
            linkText="Start A Project"
          />
        </StyledLinks>
        { Component }
      </Container>
    </StyledSection>
  )
}


export default ServiceHero;
