import styled from "styled-components";

import {
  COLORS,
  CONTAINERS,
  PADDINGS,
  SCREENS
} from "../../../theme/style-constants";


export const StyledSection = styled.section`
  width: 100%;
`;

export const Container = styled.div`
  max-width: ${ CONTAINERS.largeDesktop };
  width: 90%;
  margin: 0 auto;
  padding: ${ PADDINGS.container.section.mobile };

  @media (min-width: ${ SCREENS.laptop }) {
    padding: ${ PADDINGS.container.section.laptop };
  }
`;

export const StyledHeading = styled.h1`
  max-width: calc(${ CONTAINERS.largeDesktop });
  margin: 0 0 2rem;

  @media (min-width: ${ SCREENS.tablet }) {
    span {
      display: block;
    }
  }
`;

export const StyledDescription = styled.p`
  max-width: calc(${ CONTAINERS.largeDesktop } * 0.6);
  margin: 0 0 3rem;
`;

export const StyledServiceItems = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 0 2rem;

  > p {
    margin: 0;

    > span {
      color: ${ COLORS.text.orange };
    }
  }

  @media (min-width: ${ SCREENS.laptop }) {
    flex-direction: row;
  }
`;

export const StyledLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin: 0 0 5rem;

  a {
    margin-right: 1.5rem;
  }
`;
