import * as React from "react";
import { graphql } from "gatsby";

import Section from "../components/Section";

import CallToAction from "../components/CallToAction";
import Carousel from "../components/Carousel";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ServiceHero from "../components/Hero/ServiceHero";
import Ticker from "../components/Ticker";

import SecondaryButton from "../components/Button/SecondaryButton";


export const query = graphql`
  query Service($id: String) {
    mdx(id: {
      eq: $id
    }) {
      id
      body
      frontmatter {
        title
        pageTitle
        description
        serviceItems
      }
    }

    portfolio: allMdx(
      filter: {
        frontmatter: {
          template: {
            eq: "portfolio"
          }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            brandingCard {
              childImageSharp {
                gatsbyImageData(
                  width: 1200
                  quality: 80
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            contentCard {
              childImageSharp {
                gatsbyImageData(
                  width: 1200
                  quality: 80
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            marketingCard {
              childImageSharp {
                gatsbyImageData(
                  width: 1200
                  quality: 80
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            webCard {
              childImageSharp {
                gatsbyImageData(
                  width: 1200
                  quality: 80
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            webLink
          }
          fields {
            slug
          }
        }
      }
    }

    testimonials: allMdx(
      filter: {
        frontmatter: {
          template: {
            eq: "testimonial"
          }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            company
            reviewer
            testimonial
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  quality: 90
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`;


const ServiceTemplate = ({
  data
}) => {
  const {
    mdx: {
      frontmatter: {
        title,
        pageTitle,
        description,
        serviceItems
      }
    },
    portfolio,
    testimonials
  } = data;

  return (
    <Layout>
      <SEO title={ pageTitle } />

      <ServiceHero
        pageTitle={ pageTitle }
        pageDescription={ description }
        serviceItems={ serviceItems }
        Component={
          <Ticker
            template="marketing-partner"
            itemWidth={ 16 }
          />
        }
      />

      { title === 'Brand Identity' &&
        <Section
          sectionTitle="Our Work"
          sectionDescription="We have had the pleasure of working with small and large organizations in all industries to help redefine their digital marketing efforts."
          isComponentFullLength
          Component={
            <Carousel
              layout="portfolio"
              nodes={ portfolio.edges }
              service="brand"
              linkLocation="/portfolio"
              linkText="Explore More"
              options={
                {
                  loop: true,
                }
              }
            />
          }
        />
      }

      { title === 'Content Creation' &&
        <Section
          sectionTitle="Our Work"
          sectionDescription="We have had the pleasure of working with small and large organizations in all industries to help redefine their digital marketing efforts."
          isComponentFullLength
          Component={
            <Carousel
              layout="portfolio"
              nodes={ portfolio.edges }
              service="content"
              linkLocation="/portfolio"
              linkText="Explore More"
              options={
                {
                  loop: true,
                }
              }
            />
          }
        />
      }

      { title === 'Website Design' &&
        <Section
          sectionTitle="Our Work"
          sectionDescription="We have had the pleasure of working with small and large organizations in all industries to help redefine their digital marketing efforts."
          isComponentFullLength
          Component={
            <Carousel
              layout="portfolio"
              nodes={ portfolio.edges }
              service="web"
              linkLocation="/portfolio"
              linkText="Explore More"
              options={
                {
                  loop: true,
                }
              }
            />
          }
        />
      }

      { title === 'Digital Marketing' &&
        <Section
          sectionTitle="Our Work"
          sectionDescription="We have had the pleasure of working with small and large organizations in all industries to help redefine their digital marketing efforts."
          isComponentFullLength
          Component={
            <Carousel
              layout="portfolio"
              nodes={ portfolio.edges }
              service="marketing"
              linkLocation="/portfolio"
              linkText="Explore More"
              options={
                {
                  loop: true,
                }
              }
            />
          }
        />
      }

      <Section
        sectionTitle="Award-Winning Digital Marketing Agency"
        sectionDescription="Schedule your marketing consultation today."
        textAlignment="center"
        containerized
        Component={
          <CallToAction
            ButtonComponent={
              <SecondaryButton
                linkLocation="/contact"
                linkText="Start A Project"
              />
            }
          />
        }
      />

      <Section
        sectionTitle="Our Partners"
        sectionDescription="We have had the pleasure of working with small and large organizations in all industries to help redefine their digital marketing efforts."
        isComponentFullLength
        Component={
          <Ticker
            template="portfolio"
            itemWidth={ 10 }
          />
        }
      />

      <Section
        sectionTitle="Testimonials"
        Component={
          <Carousel
            layout="testimonial"
            nodes={ testimonials.edges }
            options={
              {
                loop: true,
              }
            }
            buttonsEnabled
          />
        }
      />
    </Layout>
  )
}


export default ServiceTemplate;
